* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

:root {
  --separation: 2rem;
  --corners: 3px;
}

.dark {
  --fg: #f1f2f6;
  --bg: #1d1d1d;
  --border: 1px solid white;
}

.light {
  --bg: white;
  --fg: black;
  --border: 1px solid black;
}

/* FONT WEIGHTS: 100, 300, 400, 600 */

.App,
#root,
body {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
}

body {
  background: var(--bg);
  color: var(--fg);
  font-family: "roc-grotesk", sans-serif;
}

a {
  color: var(--fg);
  text-decoration: none;
}

ul,
li {
  width: 100%;
  list-style-type: none;
}

.navbar {
  width: 100%;
}

.tabs {
  overflow: auto;
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: var(--bg);
  border-bottom: .5px solid #bdc3c7
}

.tab {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.divider {
  height: 30px;
  border: .25px solid #bdc3c7;
  position: absolute;
  right: 0;
}

.tab-title {
  font-weight: 300;
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
  margin: 0 4rem;
  text-overflow: ellipsis;
}

.container {
  min-width: 35%;
  margin: 2rem;
  animation: slide-up 1s cubic-bezier(0.800, 0.110, 0.110, 0.800);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.footer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-items: center;
}

.modal {
  display: flex;
  flex-direction: column;
  background: var(--bg);
  color: var(--fg);
  border: var(--border);
  padding: 2rem;
  font-size: 1.5rem;
}

.modal-btn {
  padding: 1rem !important;
}

.btn {
  flex-grow: 1;
}

.todo {
  border: var(--border);
  padding: 2rem;
}

.todo-list {
  border: var(--border);
  padding: 1.5rem;
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editing-icon {
  font-size: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}

.todo-heading {
  font-size: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 450px;
}

.delete-icon {
  margin-left: 1.5rem;
}

.heading-input {
  font-family: "roc-grotesk", sans-serif;
  border: none;
  outline: none;
  font-weight: 600;
}

.fw {
  display: flex;
  margin-top: 1rem;
}

.item-label {
  position: absolute;
  left: -1000px;
}

form {
  width: 100%;
}

.todo-search {
  background: var(--bg);
  border: var(--border);
  color: var(--fg);
  padding: 1rem;
  outline: none;
  width: 100%;
}

.btn {
  padding: 0 1.5rem;
  background: var(--bg);
  color: var(--fg);
  border: var(--border);
  margin-left: .5rem;
}

.delete-all-btn {
  width: 100%;
  margin: 0;
  padding: 1rem 2rem;
}

.btn:hover {
  background: var(--fg);
  color: var(--bg);
}

.item {
  flex-direction: row;
}

.item-content {
  display: flex;
  flex-direction: row;
  padding: .5rem 0;
  align-items: center;
  font-size: 1.5rem;
}

.content-left {
  flex-grow: 1;
  width: 100%;
}

.item-text {
  font-size: 1.5rem;
  font-weight: 300;
  transform: translateY(5%);
  outline: none;
  font-family: "roc-grotesk", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
}

.item-text:hover {
  text-decoration: line-through;
}

.item-content input[type=checkbox] {
  display: none;
}

.checkbox {
  margin-right: 1rem;
  font-size: 1.5rem;
}

.no-items-heading {
  font-weight: 500;
  font-size: 1.5rem;
  padding: .5rem 0;
}

.error {
  color: red;
}

.center {
  justify-content: center;
}

.theme-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.DialogOverlay {
  background: var(--bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .9;
  place-items: center;
  overflow-y: auto;
}

.DialogContent {
  border: var(--border);
  border-radius: 0;
  width: 20%;
  padding: 2rem;
  text-align: center;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 15%;
  background: var(--bg);
}

.DialogTitle {
  margin-bottom: 1rem;
}

.DialogDescription {
  font-size: 1.35rem;
  line-height: 2rem;
}

.btn-container {
  width: 100%;
  display: flex;
  margin-top: 1rem;
}

.delete-list-btn {
  padding: 1rem;
}

.confirm-btn {
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: 0;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .container {
    min-width: 60%;
  }

  .item-text {
    width: 200px;
  }

  .todo-heading {
    width: 400px;
  }
}

@media only screen and (max-width: 700px) {
  .DialogContent {
    width: 80%;
  }

  .container {
    min-width: 90%;
  }

  .item-text {
    width: 250px;
  }

  .todo-heading {
    width: 300px;
  }
}